import { createRouter, createWebHistory } from 'vue-router'
// import { defineAsyncComponent } from 'vue'
// import Login from "../views/user/Login.vue";
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'Index',
            component: () => import(`../views/home/Index.vue`)
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import(`../views/user/Login.vue`)
        },
        {
            path: '/home',
            name: 'Home',
            component: () => import(`../views/home/Index.vue`)
        },{
            path: '/m/home',
            name: 'HomeMobile',
            component: () => import(`../views/home/MobileIndex.vue`)
        }, {
            path: '/bot',
            name: 'Bot',
            component: () => import(`../views/home/BotDetailMobile.vue`)
        },{
            path: '/bot/orders',
            name: 'BotOrders',
            component: () => import(`../views/home/OrdersRecords.vue`)
        },
        {
            path: '/trade',
            name: 'Trade',
            component: () => import(`../views/trade/Index.vue`)
        },{
            path: '/trade-major',
            name: 'TradeNew',
            component: () => import(`../views/trade/Index2.vue`)
        }, {
            path: '/portfolio',
            name: 'Portfolio',
            component: () => import(`../views/portfolio/Index.vue`)
        },{
            path: '/portfolio-cards',
            name: 'PortfolioCards',
            component: () => import(`../views/portfolio/Index2.vue`)
        }, {
            path: '/portfolio-details/:id',
            name: 'PortfolioScaffold',
            component: () => import(`../views/portfolio/PortfolioScaffold2.vue`)
        },
        {
            path: '/account',
            name: 'Account',
            component: () => import(`../views/account/Index.vue`)
        }, {
            // path: '/lens',
            // name: 'Lens',
            // component: () => import(`../views/lens/Index.vue`)
        }, {
            path: '/setting',
            name: 'Setting',
            component: () => import(`../views/setting/Index.vue`)
        },
        {
            path: '/aave',
            name: 'Aave',
            component: () => import(`../views/aave/Index.vue`)
        },
        {
            path: '/uniswap',
            name: 'Uniswap',
            component: () => import(`../views/uniswap/Index.vue`)
        }, {
            path: '/kline',
            name: 'Kline',
            component: () => import(`../views/trade/Kline.vue`)
        },
        {
            path: '/reset',
            name: 'Reset',
            component: () => import(`../views/user/Reset.vue`)
        },
        {
            path: '/register',
            name: 'Register',
            component: () => import(`../views/user/Register.vue`)
        },{
            path: '/webhook',
            name: 'Webhook',
            component: () => import(`../views/webhook/Index.vue`)
        },{
            path: '/blackmagic',
            name: 'Blackmagic',
            component: () => import(`../views/blackmagic/Index.vue`)
        },{
            path: '/hawkeye',
            name: 'Hawkeye',
            component: () => import(`../views/hawkeye/Index.vue`)
        },{
            path: '/defi-sdk',
            name: 'DefiSDK',
            component: () => import(`../views/defi-sdk/Index.vue`)
        },{
            path: '/plan',
            name: 'Plan',
            component: () => import(`../views/plan/Index.vue`)
        },{
            path: '/paymentSuccess',
            name: 'paymentSuccess',
            component: () => import(`../views/plan/PaymentSuccess.vue`)
        },{
            path: '/paymentCanceled',
            name: 'paymentCanceled',
            component: () => import(`../views/plan/PaymentCanceled.vue`)
        }
    ]
})

router.beforeEach((to, from, next) => {
    // if (to.meta.title) {
    //     document.title = `${to.meta.title}`;
    // }
    next()
})
export default router;