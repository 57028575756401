<script setup>
import { ref,defineProps,computed,defineEmits } from 'vue';
import { PublicStore } from "@/stores/public";
const publicStore = PublicStore()
const rules = {
  required: (value) => {
    return !!value || 'Required.'
  },
  email: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || "Invalid e-mail.";
  },
}
const errorText = ref('')
const email = ref('')
const inviteData = ref({})
const inviteState = ref(false)
const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  }
})
const visible = computed({
  get: () => props.modelValue,
  set: (val) => {
    inviteData.value = {}
    inviteState.value = false
    email.value = ''
    errorText.value = ''
    emits('update:modelValue', val)
  }
})
const inviteUrl = computed(() => {
  return `${location.origin}/register?token=${inviteData.value.token}`
})
const userInfo = computed(() => {
  return publicStore.userInfo
})

const createInvitation = async() => {
  inviteState.value = false
  errorText.value = rules.required(email.value) && rules.email(email.value)
  if(errorText.value != true) {
    return
  }
  
  let res = await publicStore.createInvitation({
    email:email.value
  })
  if(res.success) {
    email.value = ''
    inviteState.value = true
    inviteData.value = res.data
    publicStore.getUserInfoFuc()
  }
}

</script>

<template>
  <v-dialog v-model="visible" persistent max-width="550" :retain-focus="false">
    <v-card>
      <div class="pa-4 pt-3 pb-3 card-head  d-flex justify-space-between align-center">
        <span class="text-uppercase font-weight-black font-primary-14">Invite Friends</span>
        <v-btn density="compact" variant="text" icon="mdi-close" @click="visible = false"></v-btn>
      </div>
      <v-divider></v-divider>
      <div class="pa-6">
        <div class="d-flex align-center  mb-2"><v-icon size="x-small">mdi-information</v-icon><span class="font-secondary-12 ml-1 mr-1">Number of invitations remaining:</span> <span class="font-primary-14 font-weight-bold">{{userInfo.invitationLimit}}</span></div>
        <div class="input-container">
          <input required="" placeholder="Email Address" type="email" v-model="email">
          <button class="invite-btn" type="button" @click="createInvitation">
            Invite
          </button>
        </div>
        <p class="mt-2 font-secondary-12 red-color pl-2" v-if="errorText != true">{{errorText}}</p>
        <template v-if="inviteState">
          <p class="font-secondary-12 mb-2 mt-4">
            Friend's email address
          </p>
          <div class="invite-url-box pa-3">
            {{inviteData.email}}
          </div>
          <div class="d-flex justify-space-between align-center mt-4">
            <p class="font-secondary-12">
              Copy the link and send it to your friends to complete the invitation
            </p>
            <v-btn size="small" variant="text" class="copyBtn invite-code-btn" icon="mdi-content-copy" :data-clipboard-text="inviteUrl"></v-btn>
          </div>
          <div class="invite-url-box pa-3 font-primary-14">
            {{ inviteUrl }}
          </div>
        </template>
      </div>
      <v-divider></v-divider>
      <div class="foot d-flex justify-end align-center pa-4 pt-2 pb-2">
        <v-btn
          @click="visible = false"
          variant="text"
          color="#fff"
          class="rounded-pill pl-3 pr-3 ml-4"
        >
        <span class="font-primary-12 white--text">Cancel</span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.input-container {
  position: relative;
  display: flex;
  height: 2.8rem;
  width: 100%;
  min-width: 200px;
  border-radius: 8px;
  box-shadow: 20px 20px 30px rgba(0, 0, 0, .05);
}

.input-container input {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  border: 1px solid  #444;
  background-color: transparent;
  padding: 0.625rem 70px 0.625rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: #fff;
  outline: none;
  transition: all .15s cubic-bezier(0.4, 0, 0.2, 1);
}

.input-container input:focus {
  border: 1px solid #6256CA;
}

.invite-btn {
  position: absolute;
  width: 65px;
  right: 4px;
  top: 4px;
  bottom: 4px;
  z-index: 10;
  border-radius: 4px;
  background-color: #6256CA;
  color: #fff;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  transition: .6s ease;
}

.invite-btn:hover {
  right: 2px;
  top: 2px;
  bottom: 2px;
  border-radius: 8px;
}

.input-container input:placeholder-shown ~ .invite-btn {
  pointer-events: none;
  background-color: gray;
  opacity: 0.5;
}
.invite-url-box {
  border-radius: 8px;
  border: 1px solid #444;
  background-color: #171717;
  overflow-x: auto;
  unicode-bidi: isolate;
  font-size: 13px;
  // white-space: pre;
  font-family: monospace, monospace;
}
</style>